import React, { useState, useEffect } from "react";
import "./Highlights.scss";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import Fade from "@mui/material/Fade";
import TablePagination from "@mui/material/TablePagination";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

import axios from "axios";

const paperStyle = {
  padding: "20px",
};

const buttonStyle = {
  marginBottom: "20px",
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  maxHeight: "80%",
};

export default function Highlights() {
  const [highlights, setHighlights] = useState([]);

  // const [filePath, setFilePath] = useState("");
  const [uploadText, setUploadText] = useState("Загрузить обложку");

  const [currentHightlight, setCurrentHighlight] = useState({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - highlights.length) : 0;

  const [createVisibility, setCreateVisibility] = useState(false);
  const [editVisibility, setEditVisibility] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState(false);

  const [name, setName] = useState("");
  const [coverUrl, setCoverUrl] = useState("");
  const [sortnum, setSortnum] = useState(1);

  const [validationError, setValidationError] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let config = localStorage.getItem("configuration");

    if (config !== null) {
      let token = JSON.parse(config).token;

      axios
        .get(`https://апи.звеножизни.рф/api/administrator/get-highlights`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response);
          setHighlights(response.data);
        })
        .catch((error) => {
          alert(error.response.data);
        });
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createHighlight = () => {
    if (name === "") {
      return setValidationError("Не указано название категории.");
    }

    if (sortnum === 0) {
      return setValidationError("Не указан номер сортировки.");
    }

    if (coverUrl === "") {
      return setValidationError("Не загружена обложка.");
    }

    setLoading(true);

    let config = localStorage.getItem("configuration");

    if (config !== null) {
      let token = JSON.parse(config).token;

      axios
        .post(
          `https://апи.звеножизни.рф/api/administrator/create-highlight?name=${name}&coverUrl=${coverUrl}&sortNum=${sortnum}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setCreateVisibility(false);
          setName("");
          setCoverUrl("");
          setSortnum(0);
          setLoading(false);
          setHighlights((old) => [...old, response.data]);
          setValidationError("");
          setUploadText("Загрузить обложку");
        })
        .catch((error) => {
          console.log(error);
          setCreateVisibility(false);
          setName("");
          setCoverUrl("");
          setSortnum(0);
          setLoading(false);
          setValidationError("");
          setUploadText("Загрузить обложку");
        });
    }
  };

  const updateHighlight = () => {
    if (name === "") {
      return setValidationError("Не указано название категории.");
    }

    if (sortnum === 0) {
      return setValidationError("Не указан номер сортировки.");
    }

    setLoading(true);

    let config = localStorage.getItem("configuration");

    if (config !== null) {
      let token = JSON.parse(config).token;

      axios
        .post(
          `https://апи.звеножизни.рф/api/administrator/update-highlight?id=${currentHightlight.id}&name=${name}&coverUrl=${coverUrl}&sortNum=${sortnum}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setEditVisibility(false);
          setName("");
          setCoverUrl("");
          setSortnum(0);
          setLoading(false);
          setHighlights((prev) => {
            let newState = prev.map((high) => {
              if (high.id === response.data.id) {
                return {
                  ...high,
                  name: response.data.name,
                  coverUrl: response.data.coverUrl,
                  sortNum: response.data.sortNum,
                };
              }

              return high;
            });

            return newState;
          });
          setValidationError("");
          setUploadText("Загрузить обложку");
        })
        .catch((error) => {
          alert(error.response.data);
          setEditVisibility(false);
          setName("");
          setCoverUrl("");
          setSortnum(0);
          setLoading(false);
          setValidationError("");
          setUploadText("Загрузить обложку");
        });
    }
  };

  const deleteHighlight = () => {
    let config = localStorage.getItem("configuration");

    if (config !== null) {
      let token = JSON.parse(config).token;

      setLoading(true);

      axios
        .get(
          `https://апи.звеножизни.рф/api/administrator/delete-highlight?id=${currentHightlight.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          setDeleteVisibility(false);
          setLoading(false);
          setHighlights((current) =>
            current.filter((cat) => {
              return cat.id !== response.data;
            })
          );
        })
        .catch((error) => {
          alert(error.response.data);
          setDeleteVisibility(false);
          setLoading(false);
        });
    }
  };

  const saveFile = (event) => {
    const formData = new FormData();
    formData.append("formFile", event.target.files[0]);
    formData.append("fileName", event.target.files[0].name);

    let config = localStorage.getItem("configuration");

    if (config !== null) {
      let token = JSON.parse(config).token;

      try {
        axios
          .post(
            "https://апи.звеножизни.рф/api/administrator/upload-file",
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((resp) => {
            // setFilePath(
            //   `https://апи.звеножизни.рф/Files/${event.target.files[0].name}`
            // );
            setCoverUrl(
              `https://апи.звеножизни.рф/Files/${event.target.files[0].name}`
            );
            setUploadText("Обложка загружена");
          })
          .catch((error) => {
            console.log(error);
            setUploadText(
              "Не удалось загрузить обложку, попробуйте другой формат изображения."
            );
          });
      } catch (error) {
        console.log(error);
        alert("Не удалось загрузить изображение.");
      }
    }
  };

  return (
    <Paper style={paperStyle}>
      <Stack direction={"row"} spacing={2}>
        <Typography variant="h6" component="p" style={{ fontWeight: "600" }}>
          Хайлайты
        </Typography>
        <Button
          variant="contained"
          color="primary"
          style={buttonStyle}
          onClick={() => setCreateVisibility(true)}
        >
          Добавить
        </Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead rowcount={highlights.length}>
            <TableRow>
              <TableCell align="center">Идентификатор</TableCell>
              <TableCell align="center">Название</TableCell>
              <TableCell align="center">Номер сортировки</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? highlights.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : highlights
            ).map((highlight) => (
              <TableRow
                key={highlight.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{highlight.id}</TableCell>
                <TableCell align="center">{highlight.name}</TableCell>
                <TableCell align="center">{highlight.sortNum} </TableCell>
                <TableCell
                  align="center"
                  onClick={() => {
                    setCurrentHighlight(highlight);
                    setName(highlight.name);
                    setSortnum(highlight.sortNum);
                    setCoverUrl(highlight.coverUrl);
                    setEditVisibility(true);
                  }}
                >
                  <Button variant="contained" color="primary" size={"small"}>
                    Редактировать
                  </Button>
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => {
                    setCurrentHighlight(highlight);
                    setDeleteVisibility(true);
                  }}
                >
                  <Button variant="contained" color="error" size={"small"}>
                    Удалить
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 63 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={highlights.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* START OF Модальное окно добавления нового хайлайта */}
      <Modal
        open={createVisibility}
        onClose={(event, reason) =>
          reason !== "backdropClick"
            ? setCreateVisibility(false)
            : console.log()
        }
        aria-labelledby="create-modal-title"
        closeAfterTransition
        aria-describedby="create-modal-sortNum"
      >
        <Fade in={createVisibility}>
          <Box sx={modalStyle}>
            <Stack spacing={2}>
              <Typography id="create-modal-title" variant="h6" component="h2">
                Создать новый хайлайт
              </Typography>
              <Stack spacing={2}>
                <Button variant="contained" component="label">
                  {uploadText}
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={saveFile}
                  />
                </Button>
                <TextField
                  label="наименование"
                  variant="outlined"
                  value={name}
                  disabled={loading}
                  onChange={(event) => setName(event.target.value)}
                />
                <TextField
                  label="номер сортировки"
                  variant="outlined"
                  type={"number"}
                  value={sortnum}
                  disabled={loading}
                  onChange={(event) => setSortnum(event.target.value)}
                />
              </Stack>
              <Collapse in={validationError === "" ? false : true}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="error"
                      size="small"
                      onClick={() => {
                        setValidationError("");
                      }}
                    ></IconButton>
                  }
                  sx={{ mb: 2 }}
                  severity="error"
                >
                  {validationError}
                </Alert>
              </Collapse>
              <Stack
                spacing={2}
                justifyContent="space-evenly"
                alignItems="center"
                direction="row"
              >
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  startIcon={<SendIcon />}
                  onClick={() => createHighlight()}
                >
                  Создать
                </LoadingButton>
                <Button
                  disabled={loading}
                  variant="outlined"
                  onClick={() => {
                    setCreateVisibility(false);
                    setName("");
                    setCoverUrl("");
                    setSortnum(0);
                    setValidationError("");
                    setUploadText("Загрузить обложку");
                  }}
                >
                  Отмена
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Fade>
      </Modal>
      {/* END OF Модальное окно добавления нового хайлайта */}

      {/* START OF Модальное окно удаления хайлайта */}
      <Dialog
        open={deleteVisibility}
        onClose={(event, reason) =>
          reason !== "backdropClick"
            ? setDeleteVisibility(false)
            : console.log()
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-sortNum"
      >
        <DialogTitle id="alert-dialog-title">
          {`Удалить хайлайт ${currentHightlight.name}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-sortNum">
            После удаления хайлайта восстановить его будет не возможно.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            variant="contained"
            color={"error"}
            size={"small"}
            startIcon={<DeleteIcon />}
            onClick={() => deleteHighlight()}
          >
            Удалить
          </LoadingButton>
          <Button
            disabled={loading}
            variant="outlined"
            size={"small"}
            onClick={() => setDeleteVisibility(false)}
          >
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
      {/* END OF Модальное окно удаления хайлайта */}

      {/* START OF Модальное окно редактирования хайлайта */}
      <Modal
        open={editVisibility}
        onClose={(event, reason) =>
          reason !== "backdropClick" ? setEditVisibility(false) : console.log()
        }
        aria-labelledby="update-modal-title"
        closeAfterTransition
        aria-describedby="update-modal-sortNum"
      >
        <Fade in={editVisibility}>
          <Box sx={modalStyle}>
            <Stack spacing={2}>
              <Typography id="update-modal-title" variant="h6" component="h2">
                Изменение параметров категории
              </Typography>
              <Stack spacing={2}>
                <Button variant="contained" component="label">
                  {uploadText}
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={saveFile}
                  />
                </Button>
                <TextField
                  label="наименование"
                  variant="outlined"
                  value={name}
                  disabled={loading}
                  onChange={(event) => setName(event.target.value)}
                />
                <TextField
                  label="номер сортировки"
                  variant="outlined"
                  value={sortnum}
                  disabled={loading}
                  type={"number"}
                  onChange={(event) => setSortnum(event.target.value)}
                />
              </Stack>
              <Collapse in={validationError === "" ? false : true}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="error"
                      size="small"
                      onClick={() => {
                        setValidationError("");
                      }}
                    ></IconButton>
                  }
                  sx={{ mb: 2 }}
                  severity="error"
                >
                  {validationError}
                </Alert>
              </Collapse>
              <Stack
                spacing={2}
                justifyContent="space-evenly"
                alignItems="center"
                direction="row"
              >
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={() => updateHighlight()}
                >
                  Сохранить
                </LoadingButton>
                <Button
                  disabled={loading}
                  variant="outlined"
                  onClick={() => {
                    setEditVisibility(false);
                    setName("");
                    setCoverUrl("");
                    setSortnum(0);
                    setValidationError("");
                    setUploadText("Загрузить обложку");
                  }}
                >
                  Отмена
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Fade>
      </Modal>
      {/* END OF Модальное оконо редактирования категории */}
    </Paper>
  );
}
