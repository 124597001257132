import React, { useState, useEffect } from "react";
import "./Products.scss";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import Fade from "@mui/material/Fade";
import TablePagination from "@mui/material/TablePagination";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import axios from "axios";
import FileUpload from "../../../Components/FileUpload/FileUpload";

const paperStyle = {
  padding: "20px",
};

const buttonStyle = {
  marginBottom: "20px",
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  maxHeight: "80%",
};

export default function Products() {
  const [products, setProducts] = useState([]);

  const [categories, setCategories] = useState([]);

  const [types, setTypes] = useState([
    { id: 1, name: "Каталог", type: 0 },
    { id: 2, name: "Пост", type: 1 },
  ]);

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [filePath, setFilePath] = useState("");
  const [uploadText, setUploadText] = useState("Загрузить обложку");

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - products.length) : 0;

  const [createVisibility, setCreateVisibility] = useState(false);
  const [updateVisibility, setUpdateVisibility] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState(false);

  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [tags, setTags] = useState("");
  const [metatags, setMetatags] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");

  const [validationError, setValidationError] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let config = localStorage.getItem("configuration");

    if (config !== null) {
      let token = JSON.parse(config).token;

      axios
        .get("https://апи.звеножизни.рф/api/administrator/get-products", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response);
          setProducts(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("https://апи.звеножизни.рф/api/administrator/get-categories", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setCategories(response.data);
        })
        .catch((error) => {
          alert(error.response.data);
        });
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createProduct = () => {
    if (name === "") {
      return setValidationError("Не указано название продукта.");
    }

    if (description === "") {
      return setValidationError("Не указано описание продукта.");
    }

    if (filePath === "") {
      return setValidationError("Не загружена обложка");
    }

    if (price <= 0) {
      return setValidationError("Введите корректную стоимость продукта.");
    }

    if (tags === "") {
      return setValidationError("Не указаны теги продукта.");
    }

    if (metatags === "") {
      return setValidationError("Не указаны метатеги продукта продукта.");
    }

    if (category === "") {
      return setValidationError("Не выбрана категория продукта.");
    }

    if (type === "") {
      return setValidationError("Не указан тип продукта.");
    }

    let config = localStorage.getItem("configuration");

    if (config !== null) {
      let token = JSON.parse(config).token;

      let catId = 0;
      categories.forEach((cat) => {
        if (cat.name === category) {
          catId = parseInt(cat.id);
        }
      });

      setLoading(true);

      axios
        .post(
          "https://апи.звеножизни.рф/api/administrator/create-product",
          {
            name: name,
            description: description,
            price: price,
            imageURL: filePath,
            tags: tags,
            metatags: metatags,
            categoryId: catId,
            category: category,
            type: type,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(type);
          setCreateVisibility(false);
          setName("");
          setDescription("");
          setPrice(0);
          setFilePath("");
          setTags("");
          setMetatags("");
          setCategory("");
          setType("");
          setLoading(false);
          setProducts((old) => [...old, response.data]);
          setValidationError("");
        })
        .catch((error) => {
          alert(error.response.data);
          setCreateVisibility(false);
          setName("");
          setDescription("");
          setPrice(0);
          setFilePath("");
          setTags("");
          setMetatags("");
          setCategory("");
          setType("");
          setLoading(false);
          setValidationError("");
        });
    }
  };

  const updateProduct = () => {
    if (name === "") {
      return setValidationError("Не указано название продукта.");
    }

    if (description === "") {
      return setValidationError("Не указано описание продукта.");
    }

    if (price <= 0) {
      return setValidationError("Введите корректную стоимость продукта.");
    }

    if (tags === "") {
      return setValidationError("Не указаны теги продукта.");
    }

    if (metatags === "") {
      return setValidationError("Не указаны метатеги продукта продукта.");
    }

    if (category === "") {
      return setValidationError("Не выбрана категория продукта.");
    }

    if (type === "") {
      return setValidationError("Не указан тип продукта.");
    }

    let config = localStorage.getItem("configuration");

    if (config !== null) {
      let token = JSON.parse(config).token;

      let catId = 0;
      categories.forEach((cat) => {
        if (cat.name === category) {
          catId = parseInt(cat.id);
        }
      });

      setLoading(true);

      axios
        .post(
          "https://апи.звеножизни.рф/api/administrator/update-product",
          {
            id: id,
            name: name,
            description: description,
            price: price,
            imageURL: filePath,
            tags: tags,
            metatags: metatags,
            categoryId: catId,
            category: category,
            type: type,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setUpdateVisibility(false);
          setName("");
          setDescription("");
          setPrice(0);
          setFilePath("");
          setTags("");
          setMetatags("");
          setCategory("");
          setType("");
          setLoading(false);
          setProducts((prev) => {
            let newState = prev.map((prod) => {
              if (prod.id === response.data.id) {
                return {
                  ...prod,
                  name: response.data.name,
                  description: response.data.description,
                  price: response.data.price,
                  imageURL: response.data.imageURL,
                  tags: response.data.tags,
                  metatags: response.data.metaTags,
                  CategoryId: response.data.categoryId,
                  category: response.data.category,
                  type: response.data.type,
                };
              }

              return prod;
            });

            return newState;
          });
          setValidationError("");
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data);
          setUpdateVisibility(false);
          setName("");
          setDescription("");
          setPrice(0);
          setFilePath("");
          setTags("");
          setMetatags("");
          setCategory("");
          setType("");
          setLoading(false);
          setValidationError("");
        });
    }
  };

  const deleteProduct = () => {
    let config = localStorage.getItem("configuration");

    if (config !== null) {
      let token = JSON.parse(config).token;

      setLoading(true);

      axios
        .get(
          `https://апи.звеножизни.рф/api/administrator/delete-product?productId=${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setDeleteVisibility(false);
          setLoading(false);
          setProducts((current) =>
            current.filter((product) => {
              return product.id !== response.data;
            })
          );
        })
        .catch((error) => {
          alert(error.response.data);
          setDeleteVisibility(false);
          setLoading(false);
        });
    }
  };

  const saveFile = (event) => {
    setFile(event.target.files[0]);
    setFileName(event.target.files[0].name);

    const formData = new FormData();
    formData.append("formFile", event.target.files[0]);
    formData.append("fileName", event.target.files[0].name);

    let config = localStorage.getItem("configuration");

    if (config !== null) {
      let token = JSON.parse(config).token;

      try {
        axios
          .post(
            "https://апи.звеножизни.рф/api/administrator/upload-file",
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((resp) => {
            setFilePath(
              `https://апи.звеножизни.рф/Files/${event.target.files[0].name}`
            );
            console.log(event.target.files[0].name);
            setUploadText("Обложка загружена");
          })
          .catch((error) => {
            console.log(error);
            setUploadText(
              "Не удалось загрузить обложку, попробуйте другой формат изображения."
            );
          });
      } catch (error) {
        console.log(error);
        alert("Не удалось загрузить изображение.");
      }
    }
  };

  return (
    <Paper style={paperStyle}>
      <Stack direction={"row"} spacing={2}>
        <Typography variant="h6" component="p" style={{ fontWeight: "600" }}>
          Продукты
        </Typography>
        <Button
          variant="contained"
          color="primary"
          style={buttonStyle}
          onClick={() => setCreateVisibility(true)}
        >
          Добавить
        </Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead rowcount={products.length}>
            <TableRow>
              <TableCell align="center">Идентификатор</TableCell>
              <TableCell align="center">Название</TableCell>
              <TableCell align="center">Описание</TableCell>
              <TableCell align="center">Стоимость</TableCell>
              <TableCell align="center">Категория</TableCell>
              <TableCell align="center">Тип</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? products.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : products
            ).map((product) => (
              <TableRow
                key={product.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{product.id}</TableCell>
                <TableCell align="center">{product.name}</TableCell>
                <TableCell align="center">{product.description}</TableCell>
                <TableCell align="center">{product.price}</TableCell>
                <TableCell align="center">{product.category}</TableCell>
                <TableCell align="center">{product.type}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size={"small"}
                    onClick={() => {
                      setId(parseInt(product.id));
                      setName(product.name);
                      setDescription(product.description);
                      setPrice(product.price);
                      setCategory(product.category);
                      setTags(product.tags);
                      setMetatags(product.metaTags);
                      setFilePath(product.imageURL);
                      setType(product.type);
                      setUpdateVisibility(true);
                    }}
                  >
                    Редактировать
                  </Button>
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => {
                    setId(parseInt(product.id));
                    setName(product.name);
                    setDeleteVisibility(true);
                  }}
                >
                  <Button variant="contained" color="error" size={"small"}>
                    Удалить
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 63 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={products.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* START OF Модальное окно добавления нового продукта */}
      <Modal
        open={createVisibility}
        onClose={(event, reason) =>
          reason !== "backdropClick"
            ? setCreateVisibility(false)
            : console.log()
        }
        scroll={"paper"}
        aria-labelledby="create-modal-title"
        closeAfterTransition
        aria-describedby="create-modal-description"
      >
        <Fade in={createVisibility}>
          <Box sx={modalStyle}>
            <Stack spacing={2}>
              <Typography id="create-modal-title" variant="h6" component="h2">
                Создать новый продукт
              </Typography>
              <Stack spacing={2}>
                {/* <FileUpload /> */}
                {/* <input accept="image/*" type={"file"} onChange={saveFile} /> */}
                <Button variant="contained" component="label">
                  {uploadText}
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={saveFile}
                  />
                </Button>
                <TextField
                  label="название"
                  variant="outlined"
                  value={name}
                  disabled={loading}
                  onChange={(event) => setName(event.target.value)}
                />
                <TextField
                  multiline
                  label="описание"
                  variant="outlined"
                  value={description}
                  disabled={loading}
                  onChange={(event) => setDescription(event.target.value)}
                />
                <TextField
                  label="цена"
                  type={"number"}
                  variant="outlined"
                  value={price}
                  disabled={loading}
                  onChange={(event) => setPrice(event.target.value)}
                />
                <FormControl>
                  <InputLabel>категория</InputLabel>
                  <Select
                    value={category}
                    onChange={(event) => setCategory(event.target.value)}
                    input={<OutlinedInput label="категории" />}
                  >
                    {categories.map((category) => (
                      <MenuItem key={category.name} value={category.name}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="теги (через # и без пробелов - #тег1#тег2#тег3)"
                  type={"text"}
                  variant="outlined"
                  value={tags}
                  disabled={loading}
                  onChange={(event) => setTags(event.target.value)}
                />
                <TextField
                  label="метатеги (через # и без пробелов - #тег1#тег2#тег3)"
                  type={"text"}
                  variant="outlined"
                  value={metatags}
                  disabled={loading}
                  onChange={(event) => setMetatags(event.target.value)}
                />
                {/* <TextField
                  label="тип продукта (набор, одиночный)"
                  value={type}
                  type={"text"}
                  variant="outlined"
                  onChange={(event) => setType(event.target.value)}
                  disabled={loading}
                  
                /> */}
                <FormControl>
                  <InputLabel>"тип продукта (набор, одиночный)"</InputLabel>
                  <Select
                    value={type}
                    onChange={(event) => setType(event.target.value)}
                    input={<OutlinedInput label="категории" />}
                  >
                    {types.map((type) => (
                      <MenuItem key={type.id} value={type.type}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Collapse in={validationError === "" ? false : true}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="error"
                      size="small"
                      onClick={() => {
                        setValidationError("");
                      }}
                    ></IconButton>
                  }
                  sx={{ mb: 2 }}
                  severity="error"
                >
                  {validationError}
                </Alert>
              </Collapse>
              <Stack
                spacing={2}
                justifyContent="space-evenly"
                alignItems="center"
                direction="row"
              >
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  startIcon={<SendIcon />}
                  onClick={() => createProduct()}
                >
                  Создать
                </LoadingButton>
                <Button
                  disabled={loading}
                  variant="outlined"
                  onClick={() => {
                    setCreateVisibility(false);
                    setName("");
                    setDescription("");
                    setPrice(0);
                    setFilePath("");
                    setTags("");
                    setMetatags("");
                    setCategory("");
                    setType("");
                    setValidationError("");
                  }}
                >
                  Отмена
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Fade>
      </Modal>
      {/* END OF Модальное окно добавления нового продукта */}

      {/* START OF Модальное окно удаления продукта */}
      <Dialog
        open={deleteVisibility}
        onClose={(event, reason) =>
          reason !== "backdropClick"
            ? setDeleteVisibility(false)
            : console.log()
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Удалить продукт ${name}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            После удаления продукта восстановить его будет не возможно.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            variant="contained"
            color={"error"}
            size={"small"}
            startIcon={<DeleteIcon />}
            onClick={() => deleteProduct()}
          >
            Удалить
          </LoadingButton>
          <Button
            disabled={loading}
            variant="outlined"
            size={"small"}
            onClick={() => {
              setDeleteVisibility(false);
              setName("");
              setId("");
            }}
          >
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
      {/* END OF Модальное окно удаления продукта */}

      {/* START OF Модальное окно редактирования продукта */}
      <Modal
        open={updateVisibility}
        onClose={(event, reason) =>
          reason !== "backdropClick"
            ? setUpdateVisibility(false)
            : console.log()
        }
        aria-labelledby="create-modal-title"
        closeAfterTransition
        aria-describedby="create-modal-description"
      >
        <Fade in={updateVisibility}>
          <Box sx={modalStyle}>
            <Stack spacing={2}>
              <Typography id="create-modal-title" variant="h6" component="h2">
                Изменить продукт
              </Typography>
              <Stack spacing={2}>
                <Button variant="contained" component="label">
                  {uploadText}
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={saveFile}
                  />
                </Button>
                <TextField
                  label="название"
                  variant="outlined"
                  value={name}
                  disabled={loading}
                  onChange={(event) => setName(event.target.value)}
                />
                <TextField
                  multiline
                  label="описание"
                  variant="outlined"
                  value={description}
                  disabled={loading}
                  onChange={(event) => setDescription(event.target.value)}
                />
                <TextField
                  label="цена"
                  type={"number"}
                  variant="outlined"
                  value={price}
                  disabled={loading}
                  onChange={(event) => setPrice(event.target.value)}
                />
                <FormControl>
                  <InputLabel>категория</InputLabel>
                  <Select
                    value={category}
                    onChange={(event) => setCategory(event.target.value)}
                    input={<OutlinedInput label="категории" />}
                  >
                    {categories.map((category) => (
                      <MenuItem key={category.name} value={category.name}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="теги (через # и без пробелов - #тег1#тег2#тег3)"
                  type={"text"}
                  variant="outlined"
                  value={tags}
                  disabled={loading}
                  onChange={(event) => setTags(event.target.value)}
                />
                <TextField
                  label="метатеги (через # и без пробелов - #тег1#тег2#тег3)"
                  type={"text"}
                  variant="outlined"
                  value={metatags}
                  disabled={loading}
                  onChange={(event) => setMetatags(event.target.value)}
                />
                {/* <TextField
                  label="тип продукта (набор, одиночный)"
                  type={"text"}
                  variant="outlined"
                  value={type}
                  disabled={loading}
                  onChange={(event) => setType(event.target.value)}
                /> */}
                <FormControl>
                  <InputLabel>"тип продукта (набор, одиночный)"</InputLabel>
                  <Select
                    value={type}
                    onChange={(event) => setType(event.target.value)}
                    input={<OutlinedInput label="категории" />}
                  >
                    {types.map((type) => (
                      <MenuItem key={type.id} value={type.type}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Collapse in={validationError === "" ? false : true}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="error"
                      size="small"
                      onClick={() => {
                        setValidationError("");
                      }}
                    ></IconButton>
                  }
                  sx={{ mb: 2 }}
                  severity="error"
                >
                  {validationError}
                </Alert>
              </Collapse>
              <Stack
                spacing={2}
                justifyContent="space-evenly"
                alignItems="center"
                direction="row"
              >
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={() => updateProduct()}
                >
                  Сохранить
                </LoadingButton>
                <Button
                  disabled={loading}
                  variant="outlined"
                  onClick={() => {
                    setUpdateVisibility(false);
                    setName("");
                    setDescription("");
                    setPrice(0);
                    setFilePath("");
                    setTags("");
                    setMetatags("");
                    setCategory("");
                    setType("");
                    setValidationError("");
                  }}
                >
                  Отмена
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Fade>
      </Modal>
      {/* END OF Модальное оконо редактирования продукта */}
    </Paper>
  );
}
