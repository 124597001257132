import React, { useState, useEffect } from "react";
import "./Users.scss";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import axios from "axios";

export default function Users() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    let config = localStorage.getItem("configuration");

    if (config !== null) {
      let token = JSON.parse(config).token;

      axios
        .get("https://апи.звеножизни.рф/api/administrator/get-users", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response);
          setUsers(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Идентификатор</TableCell>
            <TableCell align="center">Эл. почта</TableCell>
            <TableCell align="center">Имя</TableCell>
            <TableCell align="center">Фамилия</TableCell>
            <TableCell align="center">Удален?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow
              key={user.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="center">{user.id}</TableCell>
              <TableCell align="center">{user.email}</TableCell>
              <TableCell align="center">{user.name}</TableCell>
              <TableCell align="center">{user.surname}</TableCell>
              <TableCell align="center">{user.banned ? "Да" : "Нет"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
