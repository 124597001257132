import React, { useState } from "react";
import "./SignIn.scss";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function SignIn() {
  const nav = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const signIn = () => {
    if (username.length === 0) {
      return alert("Не заполнено имя пользователя.");
    }

    if (password.length === 0) {
      return alert("Не заполнен пароль.");
    }

    // if (username === "administrator" && password === "thisispassword") {
    //   localStorage.setItem("token", "haha, no token");
    //   nav("/");
    // } else {
    //   alert("Не правильно указан логин или пароль");
    // }

    axios
      .post(
        `https://апи.звеножизни.рф/api/administrator/sign-in?email=${username}&password=${password}`,
        {}
      )
      .then(function (resp) {
        console.log(resp);
        const config = {
          token: resp.data.token,
          userId: resp.data.userId,
          username: resp.data.username,
        };

        localStorage.setItem("configuration", JSON.stringify(config));

        nav("/");
      })
      .catch(function (error) {
        alert(error.response.data);
      });
  };

  return (
    <div className="sign-in">
      <div className="form">
        <h2>Добро пожаловать!</h2>
        <TextField
          size="small"
          label="Логин"
          variant="outlined"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
        <TextField
          size="small"
          label="Пароль"
          variant="outlined"
          type={"password"}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <Button size="large" variant="contained" onClick={() => signIn()}>
          Войти
        </Button>
      </div>
    </div>
  );
}
