import React, { useState, useEffect } from "react";
import "./HighlightsData.scss";

import axios from "axios";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import Fade from "@mui/material/Fade";
import TablePagination from "@mui/material/TablePagination";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { margin } from "@mui/system";

const paperStyle = {
  padding: "20px",
};

const buttonStyle = {
  marginBottom: "20px",
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  maxHeight: "80%",
};

export default function HighlightsData() {
  const [images, setImages] = useState([]);

  const [highlights, setHighlights] = useState([]);

  const [currentHighlight, setCurrentHighlight] = useState({});

  const [filePath, setFilePath] = useState("");

  const [uploadText, setUploadText] = useState("Загрузить обложку");

  const [sortNum, setSortnum] = useState(1);

  const [validationError, setValidationError] = useState("");

  const [createVisibility, setCreateVisibility] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let config = localStorage.getItem("configuration");

    if (config !== null) {
      let token = JSON.parse(config).token;

      axios
        .get(
          `https://апи.звеножизни.рф/api/administrator/get-highlights-files`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          setImages(response.data);
        })
        .catch((error) => {
          alert(error.response.data);
        });

      axios
        .get(`https://апи.звеножизни.рф/api/administrator/get-highlights`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response);
          setHighlights(response.data);
        })
        .catch((error) => {
          alert(error.response.data);
        });
    }
  }, []);

  const saveFile = (event) => {
    const formData = new FormData();
    formData.append("formFile", event.target.files[0]);
    formData.append("fileName", event.target.files[0].name);

    let config = localStorage.getItem("configuration");

    if (config !== null) {
      let token = JSON.parse(config).token;

      try {
        axios
          .post(
            "https://апи.звеножизни.рф/api/administrator/upload-file",
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((resp) => {
            setFilePath(
              `https://апи.звеножизни.рф/Files/${event.target.files[0].name}`
            );
            setUploadText("Обложка загружена");
          })
          .catch((error) => {
            console.log(error);
            setUploadText(
              "Не удалось загрузить обложку, попробуйте другой формат изображения."
            );
          });
      } catch (error) {
        console.log(error);
        alert("Не удалось загрузить изображение.");
      }
    }
  };

  const AddHighlightFile = () => {
    // axios.post(
    //   `https://апи.звеножизни.рф/api/administrator/add-highlight-files?highlightId=${selectedHighlight.id}&url=${filePath}&sortNum=${sortNum}`
    // );
    let config = localStorage.getItem("configuration");

    if (config !== null) {
      let token = JSON.parse(config).token;

      setLoading(true);

      axios
        .post(
          `https://апи.звеножизни.рф/api/administrator/add-highlight-files?highlightId=${currentHighlight.id}&url=${filePath}&sortNum=${sortNum}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setCreateVisibility(false);
          setFilePath("");
          setCurrentHighlight({});
          setSortnum(1);
          setLoading(false);
          setValidationError("");

          axios
            .get(
              "https://апи.звеножизни.рф/api/administrator/get-highlights-files",
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              setImages(response.data);
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          setCreateVisibility(false);
          setFilePath("");
          setCurrentHighlight({});
          setSortnum(1);
          setLoading(false);
          setValidationError(error.response.data);
        });
    }
  };

  const deleteHighlightFile = () => {
    let config = localStorage.getItem("configuration");

    if (config !== null) {
      let token = JSON.parse(config).token;

      setLoading(true);

      axios
        .get(
          `https://апи.звеножизни.рф/api/administrator/delete-highlight-img?highlightId=${currentHighlight.id}&url=${currentHighlight.imageUrl}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          setDeleteVisibility(false);
          setLoading(false);
          setImages((current) =>
            current.filter((file) => file.id !== response.data)
          );
        })
        .catch((error) => {
          // alert(error.response.data);
          console.log(error);
          setDeleteVisibility(false);
          setLoading(false);
        });
    }
  };

  return (
    <Paper style={paperStyle}>
      <Stack direction={"row"} spacing={2}>
        <Typography variant="h6" component="p" style={{ fontWeight: "600" }}>
          Файлы хайлайтов
        </Typography>
        <Button
          variant="contained"
          color="primary"
          style={buttonStyle}
          onClick={() => setCreateVisibility(true)}
        >
          Добавить
        </Button>
      </Stack>
      <div className="images-group">
        {images.map((img) => {
          return (
            <Stack key={img.imageUrl} direction={"column"} spacing={2}>
              <img className="def-img" src={img.imageUrl} alt={img.imageUrl} />
              <label style={{ margin: "1rem auto" }}>{img.name}</label>
              <div
                className="delete-btn"
                onClick={() => {
                  console.log(img);
                  setCurrentHighlight(img);
                  setDeleteVisibility(true);
                }}
              >
                Удалить
              </div>
            </Stack>
          );
        })}
      </div>

      {/* START OF Модальное окно добавления нового хайлайта */}
      <Modal
        open={createVisibility}
        onClose={(event, reason) =>
          reason !== "backdropClick"
            ? setCreateVisibility(false)
            : console.log()
        }
        aria-labelledby="create-modal-title"
        closeAfterTransition
        aria-describedby="create-modal-sortNum"
      >
        <Fade in={createVisibility}>
          <Box sx={modalStyle}>
            <Stack spacing={2}>
              <Typography id="create-modal-title" variant="h6" component="h2">
                Создать новый хайлайт
              </Typography>
              <Stack spacing={2}>
                <Button
                  variant="contained"
                  disabled={loading}
                  component="label"
                >
                  {uploadText}
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={saveFile}
                    disabled={loading}
                  />
                </Button>
                <FormControl>
                  <InputLabel>категория</InputLabel>
                  <Select
                    disabled={loading}
                    value={currentHighlight}
                    onChange={(event) =>
                      setCurrentHighlight(event.target.value)
                    }
                    input={<OutlinedInput label="категории" />}
                  >
                    {highlights.map((h) => (
                      <MenuItem key={h.name} value={h}>
                        {h.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="сортировочный номер"
                  type={"text"}
                  variant="outlined"
                  value={sortNum}
                  disabled={loading}
                  onChange={(event) => setSortnum(event.target.value)}
                />
              </Stack>
              <Collapse in={validationError === "" ? false : true}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="error"
                      size="small"
                      onClick={() => {
                        setValidationError("");
                      }}
                    ></IconButton>
                  }
                  sx={{ mb: 2 }}
                  severity="error"
                >
                  {validationError}
                </Alert>
              </Collapse>
              <Stack
                spacing={2}
                justifyContent="space-evenly"
                alignItems="center"
                direction="row"
              >
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  startIcon={<SendIcon />}
                  onClick={() => AddHighlightFile()}
                >
                  Создать
                </LoadingButton>
                <Button
                  disabled={loading}
                  variant="outlined"
                  onClick={() => {
                    setCreateVisibility(false);
                    setValidationError("");
                    setUploadText("Загрузить обложку");
                  }}
                >
                  Отмена
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Fade>
      </Modal>
      {/* END OF Модальное окно добавления нового хайлайта */}

      {/* START OF Модальное окно удаления хайлайта */}
      <Dialog
        open={deleteVisibility}
        onClose={(event, reason) =>
          reason !== "backdropClick"
            ? setDeleteVisibility(false)
            : console.log()
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-sortNum"
      >
        <DialogTitle id="alert-dialog-title">
          {`Удалить файл из хайлайта ${currentHighlight.name}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-sortNum">
            После удаления файла восстановить его будет не возможно.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            variant="contained"
            color={"error"}
            size={"small"}
            startIcon={<DeleteIcon />}
            onClick={() => deleteHighlightFile()}
          >
            Удалить
          </LoadingButton>
          <Button
            disabled={loading}
            variant="outlined"
            size={"small"}
            onClick={() => setDeleteVisibility(false)}
          >
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
      {/* END OF Модальное окно удаления категории */}
    </Paper>
  );
}
