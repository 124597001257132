import React from "react";
import "./NotFound.scss";

export default function NotFound() {
  return (
    <div className="not-found">
      Извините, здесь ничего нет. <br />
      Давайте начнем с <a href="/">главной страницы</a>
    </div>
  );
}
