import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import "./App.scss";

import Footer from "./Components/Footer/Footer";

import SignIn from "./Pages/SignIn/SignIn";
import NotFound from "./Pages/NotFound/NotFound";
import Home from "./Pages/Home/Home";

function App() {
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    // получаем токен с local storage
    let config = localStorage.getItem("configuration");

    if (config === null) {
      // если пользователь не находится на странице "Войти" и у него нет токена, то
      if ((location.pathname !== "/sign-in") & (config === null)) {
        // перенаправляем его на страницу "Войти"
        navigate("/sign-in");
      }
    }
  });

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/products" element={<Home />} />
        <Route path="/categories" element={<Home />} />
        <Route path="/hightlights" element={<Home />} />
        <Route path="/hightlights-data" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
